import Home from './containers/Home'
import 'font-awesome/css/font-awesome.min.css';
import { GlobalState } from './context/GlobalState';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './components/Checkout';
import Policy from './containers/Policy';
import TermUse from './containers/TermUse';
import Thankyou from './containers/ThankYou';
function App() {
  return (
    <GlobalState>
    <div className="App">
    <BrowserRouter>
      <Routes>
      <Route index element={<Home />} />
      <Route path="privacy-policy" element={<Policy />} />
      <Route path="term-use" element={<TermUse />} />
      <Route path="thank-you" element={<Thankyou />} />

        <Route path="planoffer/:plan_name" element={<Checkout />} />

        
      </Routes>
    </BrowserRouter>
    
    </div>
    </GlobalState>
  );
}

export default App;
