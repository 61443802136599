import axios from "axios";
const filterPlans = (plans, type, name = null) => {
    let temp = plans.filter((arry)=>{
            if((arry.SubscriptionPlanName).toLowerCase() == 'watcho max' ) {
              if(name != null) {
                  return arry.SubscriptionGroupNo==name &&   arry.SubscriptionPlanDurationName ==type
              }
              else {
                  return arry.SubscriptionPlanDurationName == type
              }
            }
      });
    temp.sort((a, b) => parseFloat(b.SubscriptionPriceWithTax) - parseFloat(a.SubscriptionPriceWithTax));
    return temp;
}
var planApps = [];
const getOTTKeys =(temp) => {
    temp.map((value) => {
        if(value.SubscriptionApps && value.SubscriptionApps.length > 0) {
          value.SubscriptionApps.map((app) => {
            planApps[app.AppCode] = {'app' : app, 'compare' : [] };
          });
        }
    })
    var planAppData = Object.keys(planApps)
    return planAppData;
  }
  const compareData = (temp) => {
    var planNames = [];
    var planAppData = getOTTKeys(temp)
    temp.map((value) => {
            planNames[value.SubscriptionPlanName] = value
            if(value.SubscriptionApps && value.SubscriptionApps.length > 0) {
              value.SubscriptionApps.map((app) => {
                planApps[app.AppCode]['compare'][value.SubscriptionPlanName] = value
              });
            }
    })
    var planAppData = Object.keys(planApps)
    var planNameObj = Object.keys(planNames)
    planAppData.map((data) => {
      planNameObj.map((key) => {
          if(!planApps[data]['compare'][key]) {
            planApps[data]['compare'][key] = {}
          }
        });
    })
    var planKeys = Object.keys(planApps)
    return {planPrices : planNames, ottList : planApps, planNames : planNameObj, planKeys : planKeys}
  } 
  const removeFromString = (words, str) => {
    return words.reduce((result, word) => (result.toLowerCase()).replace(word.toLowerCase(), ''), str)
  }
  const calculatePercentage = (original_price, price_after) => {
    return (((original_price - price_after)/original_price)*100).toFixed(0);
  }
  const calculateDiscount = (discount, price) => {
    return ((parseFloat(discount)/100)*parseFloat(price));
  }
  const getUserType = (userCategory) => {
    let userType = '';
    if (userCategory == '1') {
      userType = 'dish user';
    }
    else if (userCategory == '2') {
      userType = 'd2h user'
    }
    else if (userCategory == '3') {
      userType = 'Watcho user'
    }
    else if (userCategory == '4') {
      userType = 'siti user'
    }
    else {
      userType = 'Anonymous user'
    }
    return userType;
  }
  const getIP = () => {
    const res = axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  };

  const dateFormatting = (date) => {
    var mydate = new Date(date);
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"][mydate.getMonth()];
    var str = mydate.getDate() + " " + month + ' ' + mydate.getFullYear();
    return str;
  }
export {filterPlans, getOTTKeys, compareData, removeFromString, calculatePercentage, calculateDiscount, getUserType, getIP, dateFormatting}